import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import moment from "moment"
import Prose from "../utility/prose";

const itemClass = `flex flex-col justify-center bg-zinc-200 hover:bg-zinc-300 dark:bg-zinc-600 dark:hover:bg-zinc-500 h-20 px-4 rounded-2xl flex-shrink-1 flex-grow-1`
const HeroNav = () => {
    const data = useStaticQuery(graphql`
      query MyQuery {
        strapiDocument(title: {eq: "Notice to Purchaser"}) {
          title
          document {
            url
          }
        }
        allStrapiBoardMeeting(sort: {fields: meeting_time, order: DESC}, filter: {}, limit: 12) {
          nodes {
            meeting_time
            id
          }
        }
      }
  `)
  const [meetingDate, setMeetingDate] = useState("...")
  useEffect(() => {
    //find third thursday
    let meetings = data.allStrapiBoardMeeting.nodes
    let startdate = moment().startOf('month').add(6 - moment().day("Monday").day(), 'days').startOf('week').day(1).add(14, 'days').hour('17');
    let somedate = startdate

    if(moment().isAfter(startdate, 'day')) {
      somedate = moment().add(1,'month').startOf('month').add(6 - moment().day("Monday").day(), 'days').startOf('week').day(1).add(14, 'days').hour('17');
    }

    meetings.forEach((meeting) => {
      if(moment().isBefore(moment(meeting.meeting_time),'day')) {
        somedate = moment(meeting.meeting_time)
      }
    })
    setMeetingDate(somedate.format('MMMM Do @ h:mm a'))
  }, [])
  return (
  <div className="grid grid-cols-2 lg:grid-cols-4 items-center font-semibold  justify-between text-center -mt-4 mb-8 rounded-3xl gap-4">
    <a className={itemClass} target="_blank" rel="noopener noreferrer" href={data.strapiDocument.document.url}>Notice to Purchaser</a>
    <Link className={itemClass} to="/board-meetings">Upcoming Board Meeting<br /><span className="font-normal">{meetingDate}</span></Link>
    <Link className={itemClass} to="/billing">Water Bill Payment Information</Link>
    <a className={itemClass} target="_blank" rel="noopener noreferrer" href="https://www.utilitytaxservice.com/AccountSrch">View or Pay your Taxes Online</a>
  </div>
)}

export default HeroNav

