import React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import Content from "../components/content"
import NewsPreview from "../components/utility/news-preview"
import HeroNav from "../components/widgets/heroNav"
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = ({data}) => {
  const page = data.strapiHomepage
  const content = page.content
  return (
  <>
    <Seo title={page.title} />
    {/* <Prose>
      <h1 className="mb-14">{page.title}</h1>
    </Prose> */}
    <HeroNav />
    <Content content={content}></Content>
    <NewsPreview />
    <Link to="/alerts" className="m-auto w-full mt-12 lg:mt-0 px-6 mb-14">
      <StaticImage className="w-full h-auto rounded-3xl shadow-xl dark:brightness-75" src="../images/Alert-CTA.png" layout="constrained" aspectRatio={3840/1506} alt="District Alert System: The District has implemented a digital notification system to alert residents quickly of a water, sewer, drainage service impacts. Click here to register!" />
    </Link>
  </>
)}

export default IndexPage


export const indexPageQuery = graphql`
  query indexPage {
    strapiHomepage {
      id
      title
      content {
        ... on STRAPI__COMPONENT_PAGE_ALERT {
          ...pageAlert
        }
        ... on STRAPI__COMPONENT_PAGE_CARDS {
          ...pageCards
        }
        ... on STRAPI__COMPONENT_PAGE_GALLERY {
          ...pageGallery
        }
        ... on STRAPI__COMPONENT_PAGE_IMAGE {
          ...pageImage
        }
        ... on STRAPI__COMPONENT_PAGE_IMAGE_TEXT {
          ...pageImageText
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT {
          ...pageText
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT_IMAGE {
          ...pageTextImage
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT_TEXT {
          ...pageTextText
        }
        ... on STRAPI__COMPONENT_PAGE_WIDGET {
          ...pageWidget
        }
      }
    }
  }
`
